import {
  ArrowDropDown,
  DriveFileRenameOutline,
  Functions,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Grid,
  ListItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CardButton from 'components/cardButton';
import { useModal } from 'components/modal';
import React, { useState } from 'react';
import { formatName, formatNameReverse } from 'utils/fields';

type CreateEntityProps = React.PropsWithChildren;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function CreateFieldModal(props: CreateEntityProps) {
  const [regularOrComputed, setRegularOrComputed] = React.useState<
    'regular' | 'computed' | null
  >(null);

  return (
    <Stack gap={2}>
      <Typography mt={2} variant="h5">
        Create Field Wizard
      </Typography>
      {regularOrComputed === null ? (
        <Grid container spacing={4} p={4}>
          <Grid item xs={6}>
            <CardButton
              onClick={() => setRegularOrComputed('regular')}
              icon={<DriveFileRenameOutline />}
              title="Regular Field"
              description="A regular field that can be set and updated."
            />
          </Grid>
          <Grid item xs={6}>
            <CardButton
              onClick={() => setRegularOrComputed('computed')}
              icon={<Functions />}
              title="Computed Field"
              description="A readonly field that uses finsight to compute its value."
            />
          </Grid>
        </Grid>
      ) : regularOrComputed === 'computed' ? (
        <CreateComputedField />
      ) : (
        <span>not implemeneted</span>
      )}
    </Stack>
  );
}

function CreateComputedField() {
  const [name, setName] = useState('');
  const [id, setId] = useState('');

  const [type, setType] = useState<string | null>('auto');
  const [computeFunction, setComputeFunction] = useState<string | null>(null);

  const finalId = id ? id : formatNameReverse(name);

  return (
    <>
      <TextField
        label="Name"
        value={name}
        error={!name}
        onChange={(e) => setName(e.target.value)}
      />
      <Autocomplete
        value={type}
        options={['auto', 'number']}
        getOptionLabel={(option) => formatName(option)}
        onChange={(_, v) => setType(v)}
        renderInput={(params) => (
          <TextField {...params} label="Type" error={!type} />
        )}
      />
      <Autocomplete
        value={computeFunction}
        options={['sum']}
        getOptionLabel={(option) => formatName(option)}
        onChange={(_, v) => setComputeFunction(v)}
        renderInput={(params) => (
          <TextField {...params} label="Function" error={!computeFunction} />
        )}
        renderOption={(props) => (
          <ListItem {...props}>
            <Stack direction="row" alignItems="center" gap={2}>
              <Functions />
              <Stack>
                <Typography variant="h6">Sum</Typography>
                <Typography>
                  Add all the values of the target fields together.
                </Typography>
              </Stack>
            </Stack>
          </ListItem>
        )}
      />
      <Accordion variant="outlined">
        <AccordionSummary expandIcon={<ArrowDropDown />}>
          <Typography>Advanced</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            fullWidth
            label="Id"
            value={finalId}
            onChange={(e) => setId(e.target.value)}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export function useCreateFieldModal() {
  const { showModal } = useModal();

  return {
    showCreateFieldModal: () => {
      showModal('', <CreateFieldModal />, '', {
        sx: {
          flex: '0 1 1000px',
        },
      });
    },
  };
}
