import { useFetcher } from 'api/fetcher';
import { FinsightEntityField } from 'shared';

export function useFieldTypes() {
  return useFetcher<{ type: string }[]>('field-types');
}

export function useField(type: string | null) {
  return useFetcher<FinsightEntityField[]>(type ? `field/${type}` : null);
}

export function useFieldData(type: string | null, name: string | null) {
  return useFetcher<FinsightEntityField[]>(
    type && name ? `field/${type}/${name}` : null,
  );
}
