import { blue } from '@mui/material/colors';
import { motion } from 'framer-motion';
import { useDraggable } from 'providers/DnDProvider';
import React, { useEffect, useState } from 'react';
import { FinsightEntity } from 'shared';
import { getCommonSortableFields } from 'utils/fields';

export default function DroppableList({
  context,
}: {
  context: {
    entitiesList: FinsightEntity[];
    entityBefore?: FinsightEntity;
    entityAfter?: FinsightEntity;
  };
}) {
  const dragContext = useDraggable();

  const [dropMode, setDropMode] = useState(false);

  useEffect(() => {
    if (!dragContext.dragItem) {
      setDropMode(false);
    }
  }, [dragContext.dragItem]);

  return (
    <motion.div
      initial={{
        height: 0,
      }}
      animate={{
        height: dropMode ? 16 : 8,
      }}
      onMouseEnter={() => {
        if (dragContext.dragItem && dragContext.dragItem.type === 'entity') {
          setDropMode(true);
        }
      }}
      onMouseLeave={() => {
        setDropMode(false);
      }}
      onMouseUp={() => {
        if (dragContext.dragItem && dragContext.dragItem.type === 'entity') {
          const targetEntity = dragContext.dragItem.data as FinsightEntity;

          if (
            context.entityBefore?.id !== targetEntity.id &&
            context.entityAfter?.id !== targetEntity.id
          ) {
            const entities: FinsightEntity[] = [targetEntity];
            if (context.entityBefore) entities.push(context.entityBefore);
            if (context.entityAfter) entities.push(context.entityAfter);

            const _commonFields = getCommonSortableFields(entities);

            const commonFields: string[] = [];

            for (const field of _commonFields) {
              if (
                targetEntity.extraFieldsMeta[field]?.schema.type !==
                  'computed' &&
                context.entityBefore?.extraFieldsMeta[field]?.schema.type !==
                  'computed' &&
                context.entityAfter?.extraFieldsMeta[field]?.schema.type !==
                  'computed'
              ) {
                commonFields.push(field);
              }
            }

            let highestIndex = -1;

            const entityIndex = context.entitiesList.findIndex(
              (x) => x.id === targetEntity.id,
            );
            const beforeIndex = context.entityBefore
              ? context.entitiesList.findIndex(
                  (x) => x.id === context.entityBefore?.id,
                )
              : -1;
            const afterIndex = context.entityAfter
              ? context.entitiesList.findIndex(
                  (x) => x.id === context.entityAfter?.id,
                )
              : -1;

            const fromAbove =
              entityIndex < beforeIndex || entityIndex < afterIndex;

            for (let i = 0; i < commonFields.length; i++) {
              const field = commonFields[i];

              const targetValue = targetEntity.extraFields[field];
              const beforeValue = context.entityBefore?.extraFields[field];
              const afterValue = context.entityAfter?.extraFields[field];

              if (context.entityBefore) {
                if (targetValue !== beforeValue) {
                  highestIndex = i;
                  break;
                }
              }

              if (context.entityAfter) {
                if (targetValue !== afterValue) {
                  highestIndex = i;
                  break;
                }
              }
            }

            const controllingFields = commonFields.slice(highestIndex);

            for (let i = 0; i < controllingFields.length; i++) {
              const field = controllingFields[i];
              // const targetValue = targetEntity.extraFields[field];
              const beforeValue = context.entityBefore?.extraFields[field];
              const afterValue = context.entityAfter?.extraFields[field];

              let updated = false;

              const orderOfCompute: ('before' | 'after')[] = [];

              if (fromAbove) {
                orderOfCompute.push('before');
                orderOfCompute.push('after');
              } else {
                orderOfCompute.push('after');
                orderOfCompute.push('before');
              }

              for (const order of orderOfCompute) {
                const currentEntity =
                  order === 'before'
                    ? context.entityBefore
                    : context.entityAfter;

                const currentValue =
                  order === 'before' ? beforeValue : afterValue;

                if (currentEntity && !updated) {
                  if (i === controllingFields.length - 1) {
                    console.log(
                      'update',
                      field,
                      'to',
                      currentValue,
                      order === 'before' ? 'minus one' : 'plus one',
                    );
                  } else {
                    console.log('update', field, 'to', currentValue);
                  }
                  // if (targetValue !== currentValue) {
                  //   if (i === controllingFields.length - 1) {
                  //     console.log(
                  //       'update',
                  //       field,
                  //       'to',
                  //       currentValue,
                  //       order === 'before' ? 'minus one' : 'plus one',
                  //     );
                  //   } else {
                  //     console.log('update', field, 'to', currentValue);
                  //   }
                  // } else {
                  //   console.log(
                  //     'update',
                  //     field,
                  //     'to',
                  //     currentValue,
                  //     order === 'before' ? 'minus one' : 'plus one',
                  //   );
                  // }
                  updated = true;
                }
              }
            }
          }
        }
      }}
    >
      <motion.div
        initial={{
          height: 0,
        }}
        animate={{
          height: dropMode ? '100%' : 0,
        }}
        style={{
          width: '100%',
          display: 'flex',
          overflow: 'hidden',
          alignItems: 'center',
        }}
      >
        <motion.div
          style={{
            width: 4,
            height: '100%',
            borderRadius: '100px',
            backgroundColor: blue[700],
          }}
        />

        <motion.div
          style={{
            height: 4,
            flexGrow: 1,
            backgroundColor: blue[700],
          }}
        />
        <motion.div
          style={{
            width: 4,
            height: '100%',
            borderRadius: '100px',
            backgroundColor: blue[700],
          }}
        />
      </motion.div>
    </motion.div>
  );
}
