import { useWorkspaces } from 'api/workspace';
import React, { createContext, useContext, useEffect, useMemo } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { Workspace } from 'shared';
import { SWRResponse } from 'swr';

type WorkspaceProviderContextType = {
  workspaceId: string | null;
  currentWorkspace: Workspace | null;
  workspaces: SWRResponse<Workspace[]>;
  setCurrentWorkspace: (id: string) => void;
};

export const WorkspaceContext = createContext(
  {} as WorkspaceProviderContextType,
);

export default function WorkspaceProvider(props: React.PropsWithChildren) {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const workspaces = useWorkspaces();

  const workspaceId =
    params.workspaceId ?? searchParams.get('workspace') ?? null;

  const currentWorkspace = useMemo(
    () =>
      workspaces.data?.find((workspace) => workspace.id === workspaceId) ??
      workspaces.data?.[0] ??
      null,
    [workspaceId, workspaces.data],
  );

  useEffect(() => {
    if (workspaces.data && location.pathname === '/' && (false as any)) {
      if (workspaces.data.length === 0) {
        navigate(`/workspace/404`, {
          replace: true,
        });
      } else {
        navigate(`/workspace/${workspaces.data[0].id}`, {
          replace: true,
        });
      }
    }
  });

  return (
    <WorkspaceContext.Provider
      value={{
        workspaces,
        workspaceId,
        currentWorkspace,
        setCurrentWorkspace: (id) => navigate(`/workspace/${id}`),
      }}
    >
      {props.children}
    </WorkspaceContext.Provider>
  );
}

export function useWorkspaceId() {
  const context = useContext(WorkspaceContext);

  return context.workspaceId;
}

export function useWorkspace() {
  const context = useContext(WorkspaceContext);

  return context.currentWorkspace;
}

export function useManageWorkspace() {
  const context = useContext(WorkspaceContext);

  return context;
}
