import {
  List,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { useFieldTypes } from 'api/fieldEditor';
import React from 'react';
import { formatName } from 'utils/fields';

export default function FieldEditorTypesPage() {
  const fieldTypes = useFieldTypes();

  return (
    <Stack mt={3}>
      <Typography variant="h5">Types</Typography>
      <List>
        {fieldTypes.data?.map((fieldType) => (
          <ListItemButton key={fieldType.type} href={fieldType.type}>
            <ListItemText primary={formatName(fieldType.type)} />
          </ListItemButton>
        ))}
      </List>
    </Stack>
  );
}
