import {
  AccountTree,
  Add,
  AttachMoney,
  ChevronLeft,
  ChevronRight,
  ExpandMore,
  GridView,
  PlaylistAddCheck,
  ShoppingCart,
  Tune,
  ViewList,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  // List,
  Stack,
  Tab,
  Tabs,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  useTheme,
} from '@mui/material';
import { EndpointUrl } from 'api/endpoint';
import { useEntity, useEntityActions, useEntityChildren } from 'api/entity';
import { useWorkspaceId } from 'api/providers/workspaceProvider';
import { useCreateEntityModal } from 'components/createEntity';
import { useCreateFieldModal } from 'components/createField';
import EditableTextField from 'components/editableTextField';
import EntityComponent from 'components/entityView';
import { FinsightEntityFieldValue } from 'components/renderField';
import DOMPurify from 'dompurify';
import Handlebars from 'handlebars';
import useIsMobile from 'hooks/useIsMobile';
import _ from 'lodash';
import { ProjectsList } from 'pages/projects';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { FinsightEntity, FinsightEntityField } from 'shared';
import { formatName, plural, sortFields } from 'utils/fields';

export function View() {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isLoading, error, data: entity } = useEntity(params.id);
  const { updateEntity } = useEntityActions();

  const [allChildren, setAllChildren] = useState(false);

  const { data: children } = useEntityChildren(params.id, allChildren);
  const workspaceId = useWorkspaceId();

  const { showCreateEntityModal } = useCreateEntityModal();
  const { showCreateFieldModal } = useCreateFieldModal();

  const isMobile = useIsMobile();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const childrenByType = useMemo(
    () =>
      children?.reduce(
        (acc, c) => ((acc[c.type] = [...(acc[c.type] || []), c]), acc),
        {} as Record<string, FinsightEntity[]>,
      ),
    [children],
  );

  const childrenTypes = useMemo(
    () =>
      Object.keys(childrenByType || {}).sort((a, b) => {
        if (a === 'project' && b !== 'project') return -1;
        if (a !== 'project' && b === 'project') return 1;

        if (a === 'task' && b !== 'task') return -1;
        if (a !== 'task' && b === 'task') return 1;

        return a.localeCompare(b);
      }),
    [childrenByType],
  );

  const tab = searchParams.get('tab');
  const setTab = useCallback(
    (v: string) => {
      setSearchParams((prev) => (prev.set('tab', v), prev), {
        replace: true,
      });
    },
    [setSearchParams],
  );

  const [viewType, setViewType] = useState<'list' | 'card'>('list');

  const [entityStore, setEntityStore] = useState<
    Record<string, FinsightEntity>
  >({});

  useEffect(() => {
    if (childrenTypes.length) {
      if (!tab || !childrenTypes.includes(tab)) setTab(childrenTypes[0]);
    }
  }, [childrenTypes, setTab, tab]);

  const entityFieldPaths = useMemo(() => {
    if (!entity) return null;
    const fields = Object.values(entity.extraFieldsMeta || {});
    if (!fields.length) return null;
    return findEntityTypes(fields, entity.extraFields).filter((x) => x.id);
  }, [entity]);

  useEffect(() => {
    if (entityFieldPaths?.length) {
      fetch(`${EndpointUrl}/workspace/${workspaceId}/entities/all`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-finsight-token': localStorage.getItem('token') || '',
        },
        body: JSON.stringify([
          {
            name: 'field_query_ids',
            property: 'id',
            operator: 'in',
            value: entityFieldPaths.map(({ id }) => id).flat(),
          },
        ]),
      }).then(async (res) => {
        const data = (await res.json()) as FinsightEntity[];
        const store: Record<string, FinsightEntity> = {};
        for (const entity of data) {
          store[entity.id] = entity;
        }
        setEntityStore(store);
      });
    }
  }, [entityFieldPaths, workspaceId]);

  const entityWithResolvedExtraFields = useMemo(() => {
    const entityCopy = structuredClone(entity);
    if (!entityCopy) return null;
    if (!entityFieldPaths) return entityCopy;

    for (const { paths, id } of entityFieldPaths) {
      _.set(entityCopy.extraFields, paths, entityStore[id]);
    }

    return entityCopy;
  }, [entity, entityFieldPaths, entityStore]);

  const theme = useTheme();

  if (isLoading)
    return (
      <Stack direction="row" pt={5} gap={3}>
        <Stack flexGrow={1}>
          <Skeleton height={90} />
          <Skeleton height={200} />
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
        </Stack>
        <Stack flex="0 0 250px">
          <Skeleton height={60} />
          <Skeleton height={60} />
          <Skeleton height={60} />
        </Stack>
      </Stack>
    );

  if (error || !entity) return 'ERRORRRRR';

  const templateVars: Record<string, any> = {};

  Handlebars.registerHelper('math', function (operator, lvalue, rvalue) {
    lvalue = parseFloat(lvalue);
    rvalue = parseFloat(rvalue);

    switch (operator) {
      case 'add':
        return lvalue + rvalue;
      case 'sub':
        return lvalue - rvalue;
      case 'mult':
        return lvalue * rvalue;
      case 'div':
        return lvalue / rvalue;
      case 'mod':
        return lvalue % rvalue;
    }

    return NaN;
  });

  Handlebars.registerHelper('getVar', function (name) {
    return templateVars[name];
  });

  Handlebars.registerHelper('setVar', function (name, value) {
    templateVars[name] = value;
  });

  Handlebars.registerHelper('style', function (...args) {
    if (args.length < 2) return '';
    const options = args.pop();
    const inline = options?.hash?.inline || true;
    let styleString = '';

    for (const name of args) {
      const key = `${name}`.toLowerCase();
      const premadeStyles: Record<string, string> = {
        'flex-v': 'display:flex;flex-direction:column;',
        'flex-h': 'display:flex;flex-direction:row;',
        'j-spaced': 'justify-content:space-between;',
        'a-center': 'align-items:center;',
        h1: `font-size:${theme.typography.h1.fontSize};font-weight:${theme.typography.h1.fontWeight};`,
        h2: `font-size:${theme.typography.h2.fontSize};font-weight:${theme.typography.h2.fontWeight};`,
        h3: `font-size:${theme.typography.h3.fontSize};font-weight:${theme.typography.h3.fontWeight};`,
        h4: `font-size:${theme.typography.h4.fontSize};font-weight:${theme.typography.h4.fontWeight};`,
        h5: `font-size:${theme.typography.h5.fontSize};font-weight:${theme.typography.h5.fontWeight};`,
        h6: `font-size:${theme.typography.h6.fontSize};font-weight:${theme.typography.h6.fontWeight};`,
        'text.secondary': `color:${theme.palette.text.secondary};`,
      };

      styleString += premadeStyles[key] || '';
    }

    for (const key in options.hash) {
      styleString += `${key}:${options.hash[key]};`;
    }

    return inline ? `style="${styleString}"` : styleString;
  });

  Handlebars.registerHelper('round', function (value, decimals) {
    if (isNaN(+value)) return value;
    if (isNaN(decimals)) decimals = 0;

    return Number(value).toFixed(decimals);
  });

  const hbr = `
    <div style="display:flex;flex-direction:column;">
      <span {{{style "h6"}}}>Summary</span>
      <span {{{style "h4"}}}>{{ name }}</span>
      <div {{{style "flex-h" "j-spaced"}}}>
        <div {{{style "flex-v"}}}>
          <span>Bill To:</span>
          <span {{{style "h6"}}}>{{extraFields.buyer}}</span>
        </div>
        <div {{{style "flex-v"}}}>
          <span>Pay To:</span>
          <span {{{style "h6"}}}>{{extraFields.seller}}</span>
        </div>
      </div>
      <hr />
      <span>Items</span>
      <div>
      {{setVar "subtotal" 0}}
      {{#each extraFields.commodities}}
        <div {{{style "flex-h" "j-spaced" "a-center"}}}>
          <div {{{style "flex-h" gap="16px"}}}>
            <img src="{{this.commodity.extraFields.image_url}}" alt="commodity" width="48" height="48" />
            <div {{{style "flex-v"}}}>
              <span>
                {{this.commodity.name}}
              </span>
              <span {{{style "text.secondary"}}}>
                {{this.commodity.extraFields.brand}}, \${{this.commodity.extraFields.price_per_unit}} /{{this.commodity.extraFields.unit}}
              </span>
            </div>
          </div>
          <div {{{style "flex-h" "a-center" gap="8px"}}}>
            {{setVar "calc_price" (math "mult" this.commodity.extraFields.price_per_unit this.quantity)}}
            {{setVar "subtotal" (math "add" (getVar "subtotal") (getVar "calc_price"))}}
            <span>{{this.quantity}}x</span>
            <span {{{style "h6"}}}>\${{round (getVar "calc_price") 2}}</span>
          </div>
        </div>
        <hr />
      {{/each}}
      </div>
      <div {{{style "flex-h" "j-spaced" "h6"}}}>
        <span>Subtotal:</span>
        <span>\${{round (getVar "subtotal") 2}}</span>
      </div>
      <div {{{style "flex-h" "j-spaced" "h6"}}}>
        <span>Other Costs:</span>
        <span>\${{extraFields.other_costs}}</span>
      </div>
      <div {{{style "flex-h" "j-spaced" "h6"}}}>
        <span>Adjustments:</span>
        <span>\${{extraFields.adjustments}}</span>
      </div>
      <div {{{style "flex-h" "j-spaced" "h5"}}}>
        <span>Calculated Total:</span>
        <span>\${{round (math "add" (math "add" extraFields.other_costs (getVar "subtotal")) extraFields.adjustments) 2}}</span>
      </div>
      <div {{{style "flex-h" "j-spaced" "h4"}}}>
        <span>Total:</span>
        <span>\${{extraFields.amount}}</span>
      </div>
    </div>
  `;

  const template = Handlebars.compile(hbr);

  const html = template(entityWithResolvedExtraFields);

  const escapedHtml = DOMPurify.sanitize(html);

  return (
    <>
      <Stack pt={3} gap={2}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" gap={1}>
            <Chip
              variant="outlined"
              size="small"
              label={formatName(entity.type)}
            />
            {entity.parents?.length && (
              <Breadcrumbs>
                {Array.from(entity.parents)
                  .reverse()
                  .map((parent) => (
                    <EntityComponent
                      key={parent.id}
                      entity={parent}
                      viewType="text"
                    />
                  ))}
              </Breadcrumbs>
            )}
          </Stack>
          {entity.type !== 'transaction' && entity.type !== 'commodity' && (
            <Button
              startIcon={<Add />}
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              New
            </Button>
          )}
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Stack gap={2} flexGrow={1} minWidth={0}>
            <EditableTextField
              fullWidth
              variant="minimal"
              value={entity.name ?? ''}
              onChange={async (v) => {
                await updateEntity(entity.id, { name: v });
              }}
              inputProps={{
                sx: {
                  fontSize: 'h4.fontSize',
                  lineHeight: '1em',
                },
              }}
            />
            <EditableTextField
              rich
              key={entity.id}
              fullWidth
              minRows={5}
              variant="outlined"
              value={entity.description ?? ''}
              placeholder="No description added, type here to add one"
              onChange={async (v) => {
                await updateEntity(entity.id, { description: v });
              }}
            />
            <Stack gap={2}>
              {sortFields(Object.values(entity.extraFieldsMeta)).map(
                (field) => (
                  <FinsightEntityFieldValue
                    viewType="entity_main"
                    editable
                    field={field}
                    key={field.name}
                    value={entity.extraFields[field.name]}
                    onChange={async (v) => {
                      await updateEntity(entity.id, {
                        extraFields: { [field.name]: v },
                      });
                    }}
                  />
                ),
              )}
            </Stack>
            {isMobile && (
              <Accordion variant="outlined">
                <AccordionSummary expandIcon={<ExpandMore />}>
                  More Fields
                </AccordionSummary>
                <AccordionDetails>
                  <Stack gap={2}>
                    {sortFields(Object.values(entity.extraFieldsMeta)).map(
                      (field) => (
                        <FinsightEntityFieldValue
                          viewType="entity_sidebar"
                          editable
                          field={field}
                          key={field.name}
                          value={entity.extraFields[field.name]}
                          onChange={async (v) => {
                            await updateEntity(entity.id, {
                              extraFields: { [field.name]: v },
                            });
                          }}
                        />
                      ),
                    )}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            )}
            <Stack gap={1} direction="row" alignItems="center">
              <Tabs
                value={tab}
                onChange={(_, v) => setTab(v)}
                sx={{ flexGrow: 1 }}
              >
                {childrenTypes.map((type) => (
                  <Tab
                    key={type}
                    value={type}
                    label={plural(formatName(type))}
                  />
                ))}
              </Tabs>
              <TextField
                value={allChildren}
                size="small"
                variant="standard"
                onChange={(e) => setAllChildren(e.target.value === 'true')}
                select
              >
                <MenuItem value="true">All Children</MenuItem>
                <MenuItem value="false">Direct Children</MenuItem>
              </TextField>
              <ToggleButtonGroup
                exclusive
                size="small"
                value={viewType}
                onChange={(_, v) => setViewType(v)}
              >
                <Tooltip title="List View">
                  <ToggleButton value="list">
                    <ViewList />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title="Card View">
                  <ToggleButton value="card">
                    <GridView />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            </Stack>
            {tab &&
              childrenByType &&
              (viewType === 'card' ? (
                <Stack
                  gap={2}
                  display="grid"
                  gridTemplateColumns="repeat(auto-fill, minmax(220px, 1fr))"
                  sx={{
                    '& > *': {
                      aspectRatio: '1 / 1',
                    },
                  }}
                >
                  {childrenByType[tab]?.map((child) => (
                    <EntityComponent
                      parentControlledCardSize
                      hideType
                      key={child.id}
                      entity={child}
                      viewType="card"
                      hideParent={!allChildren}
                    />
                  ))}
                </Stack>
              ) : (
                <ProjectsList
                  projects={childrenByType[tab]}
                  entityViewProps={{
                    hideParent: !allChildren,
                  }}
                />
                // <List>
                //   {childrenByType[tab]?.map((child) => (
                //     <EntityComponent
                //       parentControlledCardSize
                //       hideType
                //       key={child.id}
                //       entity={child}
                //       viewType="list"
                //     />
                //   ))}
                // </List>
              ))}
            {entity.type === 'transaction' && (
              <Box
                dangerouslySetInnerHTML={{ __html: escapedHtml }}
                sx={{
                  '& *': {
                    borderColor: 'divider',
                  },
                  '& hr': {
                    borderTop: 0,
                  },
                }}
              />
            )}
          </Stack>
          {!isMobile && (
            <Stack
              flexShrink={0}
              gap={1}
              width={sidebarOpen ? 250 : 48}
              overflow={!sidebarOpen ? 'hidden' : 'visible'}
              ml={sidebarOpen ? 4 : 0}
              sx={{
                transition: 'width 0.3s, margin 0.3s',
              }}
            >
              <IconButton
                sx={{
                  alignSelf: 'flex-end',
                }}
                onClick={() => setSidebarOpen((prev) => !prev)}
              >
                {sidebarOpen ? <ChevronRight /> : <ChevronLeft />}
              </IconButton>
              <Stack
                width={250}
                gap={2}
                sx={{
                  opacity: sidebarOpen ? 1 : 0,
                  transition: 'opacity 0.3s ease',
                  pointerEvents: sidebarOpen ? 'auto' : 'none',
                  userSelect: sidebarOpen ? 'auto' : 'none',
                }}
              >
                {sortFields(Object.values(entity.extraFieldsMeta)).map(
                  (field) => (
                    <FinsightEntityFieldValue
                      viewType="entity_sidebar"
                      editable
                      field={field}
                      key={field.name}
                      value={entity.extraFields[field.name]}
                      onChange={async (v) => {
                        await updateEntity(entity.id, {
                          extraFields: { [field.name]: v },
                        });
                      }}
                    />
                  ),
                )}
                <Button
                  startIcon={<Add />}
                  onClick={() => showCreateFieldModal()}
                >
                  Create New Field
                </Button>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        {entity.type === 'project' && (
          <MenuItem onClick={() => showCreateEntityModal('project', entity)}>
            <ListItemIcon>
              <AccountTree />
            </ListItemIcon>
            <ListItemText primary="New Project" />
          </MenuItem>
        )}
        <MenuItem onClick={() => showCreateEntityModal('task', entity)}>
          <ListItemIcon>
            <PlaylistAddCheck />
          </ListItemIcon>
          <ListItemText primary="New Task" />
        </MenuItem>
        <MenuItem onClick={() => showCreateEntityModal('transaction', entity)}>
          <ListItemIcon>
            <AttachMoney />
          </ListItemIcon>
          <ListItemText primary="New Transaction" />
        </MenuItem>
        <MenuItem onClick={() => showCreateEntityModal('commodity', entity)}>
          <ListItemIcon>
            <ShoppingCart />
          </ListItemIcon>
          <ListItemText primary="New Commodity" />
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() =>
            showCreateEntityModal('', entity, {
              moreClicked: true,
            })
          }
        >
          <ListItemIcon>
            <Tune />
          </ListItemIcon>
          <ListItemText primary="More" />
        </MenuItem>
      </Menu>
    </>
  );
}

function findEntityTypes(
  fields: FinsightEntityField[],
  values: unknown,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  parentPaths: string[] = [],
) {
  const final: {
    paths: string[];
    id: string;
  }[] = [];
  for (const field of fields) {
    if (values === null || values === undefined || typeof values !== 'object')
      continue;

    const valuesMap = values as Record<string, unknown>;

    if (field.schema.multiple && !Array.isArray(valuesMap[field.name]))
      continue;
    if (field.schema.type !== 'entity' && field.schema.type !== 'object')
      continue;

    const isArray = Array.isArray(valuesMap[field.name]);

    const valuesArray = (
      isArray ? valuesMap[field.name] : [valuesMap[field.name]]
    ) as unknown[];

    for (const i in valuesArray) {
      const parentPath = [...parentPaths, field.name];
      if (isArray) parentPath.push(i);

      if (field.schema.type === 'entity') {
        final.push({
          paths: parentPath,
          id: valuesArray[i] as string,
        });
      }

      if (
        field.schema.type === 'object' &&
        Array.isArray(field.schema.properties)
      ) {
        final.push(
          ...findEntityTypes(
            field.schema.properties.map((x) => ({
              name: x.name ?? '',
              schema: x,
              type: '',
            })),
            valuesArray[i] as Record<string, unknown>,
            parentPath,
          ),
        );
      }
    }
  }

  return final;
}
