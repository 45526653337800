import { MDXEditorMethods } from '@mdxeditor/editor';
import { Check, Close } from '@mui/icons-material';
import {
  Box,
  Card,
  IconButton,
  Popper,
  Stack,
  TextField,
  TextFieldProps,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import { MDXEditorStyled } from './mdxeditor';

export default function EditableTextField(
  props: Omit<TextFieldProps, 'onChange' | 'variant'> & {
    onChange?: (v: string) => Promise<void>;
    notBlank?: boolean;
    rich?: boolean;
    numeric?: boolean;
    getLabel?: (v: string) => string;
    variant?: TextFieldProps['variant'] | 'minimal';
    readonly?: boolean;
  },
) {
  const {
    value: _value,
    onChange,
    disabled: propsDisabled,
    notBlank,
    readonly,
    variant: _variant,
    numeric,
    rich,
    ...rest
  } = props;
  const value = _value ?? '';
  const [anchorEl, setAnchorEl] = useState<
    HTMLDivElement | HTMLInputElement | HTMLTextAreaElement | null
  >(null);
  const [rawValue, setRawValue] = useState<string>(`${value}`);
  const elem = useRef<HTMLDivElement | null>(null);
  const timeNow = useRef(performance.now());
  const [disabled, setDisabled] = useState(false);
  const [errored, setErrored] = useState(false);
  const [key, setKey] = useState(0);

  const mdxRef = useRef<MDXEditorMethods | null>(null);

  const variant =
    _variant === 'minimal' ? 'standard' : (_variant ?? 'standard');

  useEffect(() => {
    setRawValue(`${value}`);
    setDisabled(false);
    setErrored(false);
  }, [value]);

  let invalid = false;

  if (numeric && isNaN(Number(rawValue))) invalid = true;
  if (notBlank && !rawValue) invalid = true;

  return (
    <>
      <Popper
        open={!!anchorEl}
        anchorEl={anchorEl}
        placement="bottom-end"
        sx={{
          zIndex: 1400,
        }}
      >
        <Stack gap={0.5} direction="row">
          <Card>
            <IconButton
              size="small"
              disabled={invalid}
              onClick={async () => {
                const capturedValue = rawValue;
                setAnchorEl(null);
                setRawValue(`${value}`);
                setDisabled(true);
                try {
                  await onChange?.(capturedValue);
                } catch {
                  setErrored(true);
                } finally {
                  setDisabled(false);
                }
              }}
            >
              <Check />
            </IconButton>
          </Card>
          <Card>
            <IconButton
              size="small"
              onClick={() => {
                setAnchorEl(null);
                setRawValue(`${value}`);
                setErrored(false);
                if (rich) {
                  setKey((k) => k + 1);
                }
              }}
            >
              <Close />
            </IconButton>
          </Card>
        </Stack>
      </Popper>
      {rich ? (
        <Box
          ref={elem}
          onClick={() => {
            setAnchorEl(elem.current);
            const children = elem.current?.getElementsByClassName(
              '_contentEditable_uazmk_379',
            );

            if (
              children?.length &&
              children[0].tagName === 'DIV' &&
              !children[0].contains(document.activeElement)
            ) {
              children[0].setAttribute('tabindex', '0');
              (children[0] as HTMLDivElement).focus();
            }
          }}
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridTemplateRows: '1fr',
            '& > *': {
              gridColumn: 1,
              gridRow: 1,
            },
            ...(disabled
              ? {
                  pointerEvents: 'none',
                  opacity: 0.7,
                }
              : {}),
          }}
        >
          <MDXEditorStyled
            key={key}
            ref={mdxRef}
            markdown={rawValue}
            originalValue={`${value}`}
            readOnly={!anchorEl}
            trim={false}
            onChange={(v) => setRawValue(v)}
          />
          {props.placeholder && !anchorEl && !rawValue.length && (
            <Box
              sx={{
                mt: 1,
                padding: 2,
                pointerEvents: 'none',
              }}
            >
              {props.placeholder}
            </Box>
          )}
        </Box>
      ) : (
        <Tooltip title={readonly ? 'Readonly' : ''}>
          <TextField
            {...rest}
            key={key}
            ref={elem}
            variant={variant}
            multiline
            fullWidth
            error={errored || invalid}
            helperText={
              disabled ? 'Saving...' : errored ? 'Error' : rest.helperText
            }
            disabled={disabled || propsDisabled}
            value={
              anchorEl
                ? rawValue
                : props.getLabel
                  ? props.getLabel(rawValue)
                  : rawValue
            }
            onChange={(e) => anchorEl && setRawValue(e.target.value)}
            onFocus={(e) => {
              if (readonly) return;
              timeNow.current = performance.now();
              setAnchorEl(e.currentTarget);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Escape') {
                setRawValue(`${value}`);
                setAnchorEl(null);
                setKey((k) => k + 1);
              }
            }}
            onBlur={() => {
              if (
                rawValue === `${value}` &&
                performance.now() - timeNow.current > 100
              ) {
                setAnchorEl(null);
                // setKey((k) => k + 1);
                elem.current?.blur();
              }
            }}
            InputProps={{
              ...rest.InputProps,
              readOnly: readonly,
            }}
            sx={{
              ...props.sx,
              ...(_variant === 'minimal'
                ? {
                    '.MuiInputBase-root::before': {
                      border: 'none',
                    },
                  }
                : {}),
            }}
          />
        </Tooltip>
      )}
    </>
  );
}
