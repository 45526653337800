import { EndpointUrl } from 'api/endpoint';
import { useCurrentUser } from 'api/providers/authProvider';
import { useWorkspaceId } from 'api/providers/workspaceProvider';
import useSWR from 'swr';

function fetcher<T>(token: string | null | undefined) {
  if (!token) return null;
  return async (url: string) => {
    const response = await fetch(url, {
      headers: {
        'x-finsight-token': token,
      },
    });
    if (!response.ok) return null;
    const data = await response.json();
    return data as T;
  };
}

export function useFetcher<T>(url: string | null, workspaceScope = true) {
  const user = useCurrentUser();
  const workspaceId = useWorkspaceId();

  return useSWR(
    EndpointUrl && user && url && workspaceId
      ? workspaceScope
        ? `${EndpointUrl}/workspace/${workspaceId}/${url}`
        : `${EndpointUrl}/${url}`
      : null,
    fetcher<T>(user?.token),
  );
}
