import { Add } from '@mui/icons-material';
import {
  Button,
  List,
  ListSubheader,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useAllEntities, useRecentEntities, useRootEntities } from 'api/entity';
import { useCreateEntityModal } from 'components/createEntity';
import EntityView from 'components/entityView';
import React, { useEffect, useState } from 'react';
import { FinsightEntity, getEntityExtraFieldValue } from 'shared';
import { formatName, sortEntities } from 'utils/fields';

import DroppableList from './droppableList';

export default function ProjectsPage() {
  const recentProjects = useRecentEntities('project');
  const { showCreateEntityModal } = useCreateEntityModal();
  const [filter, setFilter] = useState<'root' | 'all'>('root');
  return (
    <Stack gap={1} pt={2}>
      <Stack direction="row" justifyContent="flex-end">
        <Button
          startIcon={<Add />}
          variant="outlined"
          onClick={() => showCreateEntityModal('project')}
        >
          New Project
        </Button>
      </Stack>
      <Typography variant="h6">Recent Projects</Typography>
      <Stack direction="row" gap={2} minWidth={0} overflow="auto">
        {recentProjects.data?.map((project) => (
          <EntityView entity={project} key={project.id} viewType="card" />
        ))}
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h6">Projects</Typography>
        <TextField
          select
          variant="standard"
          InputProps={{
            startAdornment: (
              <Typography variant="body2" pr={0.5}>
                Filter:
              </Typography>
            ),
          }}
          value={filter}
          onChange={(e) => setFilter(e.target.value as any)}
        >
          <MenuItem value="root">Root</MenuItem>
          <MenuItem value="all">All</MenuItem>
        </TextField>
      </Stack>
      {filter === 'root' ? <RootProjects /> : <AllProjects />}
    </Stack>
  );
}

function RootProjects() {
  const projects = useRootEntities('project');

  if (!projects.data?.length) {
    return null;
  }

  return <ProjectsList projects={projects.data} />;
}

function AllProjects() {
  const projects = useAllEntities('project');

  if (!projects.data?.length) {
    return null;
  }

  return <ProjectsList projects={projects.data} />;
}

export function ProjectsList({
  projects,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  entityViewProps,
}: {
  projects?: FinsightEntity[];
  entityViewProps?: Partial<React.ComponentProps<typeof EntityView>>;
}) {
  const [projectsSorted, setProjectsSorted] = useState<
    ({
      group: string;
    } & FinsightEntity)[]
  >([]);

  useEffect(() => {
    const projectsGrouped: Record<string, FinsightEntity[]> = {};
    const order: Record<string, number> = {};
    for (const project of Array.from(projects ?? []).sort(sortEntities)) {
      const extraFields = Object.values(project.extraFieldsMeta);
      extraFields.sort((a, b) => (b.schema.order ?? 0) - (a.schema.order ?? 0));
      const groupableField = extraFields.find((x) => x.schema.groupable);
      if (groupableField) {
        const value = getEntityExtraFieldValue(
          groupableField,
          project.extraFields[groupableField.name],
          true,
        );

        if (groupableField.schema.type === 'enum') {
          const rawValue = getEntityExtraFieldValue(
            groupableField,
            project.extraFields[groupableField.name],
          );
          if (
            Array.isArray(groupableField.schema.properties) &&
            typeof rawValue === 'number'
          ) {
            order[`${value}`] =
              groupableField.schema.properties[rawValue].order ?? 0;
          }
        } else {
          order[`${value}`] = 0;
        }

        projectsGrouped[`${value}`] = projectsGrouped[`${value}`] ?? [];
        projectsGrouped[`${value}`].push(project);
      } else {
        projectsGrouped[''] = projectsGrouped[''] ?? [];
        projectsGrouped[''].push(project);
      }
    }

    const finalArray = Object.entries(projectsGrouped)
      .map(([key, value]) => ({
        key,
        value,
      }))
      .sort((a, b) => order[b.key] - order[a.key])
      .flatMap((x) =>
        x.value.map((y) => ({
          ...y,
          group: x.key,
        })),
      );

    setProjectsSorted(finalArray);
  }, [projects]);

  if (!projectsSorted.length) {
    return null;
  }

  return (
    <List>
      {/* <Reorder.Group
        values={projectsSorted}
        onReorder={setProjectsSorted}
        as="div"
      > */}
      {projectsSorted.map((project, i) => (
        <React.Fragment key={project.id}>
          {(i === 0 || projectsSorted[i - 1]?.group !== project.group) && (
            <>
              <ListSubheader key={i + 'group'}>
                {formatName(project.group)}
              </ListSubheader>
              <DroppableList
                context={{
                  entitiesList: projectsSorted,
                  entityAfter: project,
                }}
              />
            </>
          )}

          {/* <Reorder.Item key={project.id} value={project}> */}
          {/* {project.name} */}
          <EntityView hideType {...entityViewProps} entity={project} />
          <DroppableList
            context={{
              entitiesList: projectsSorted,
              entityBefore: project,
              entityAfter:
                projectsSorted[i + 1]?.group === project.group
                  ? projectsSorted[i + 1]
                  : undefined,
            }}
          />
          {/* </Reorder.Item> */}
        </React.Fragment>
      ))}
      {/* </Reorder.Group> */}
    </List>
  );
}
