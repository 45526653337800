"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.verifyType = verifyType;
var types_1 = require("../types");
function verifyType(field, _value) {
    var _a, _b;
    try {
        var valuesArray = field.schema.multiple
            ? _value
            : [_value];
        if (!Array.isArray(valuesArray)) {
            // we could error, but for now just return empty array
            if (field.schema.multiple)
                return [];
            throw new Error();
        }
        if (field.schema.type === 'computed') {
            var parsedMeta = types_1.ComputedFieldMetaSchema.parse(field.schema.meta);
            verifyType(__assign(__assign({}, field), { schema: __assign(__assign({}, field.schema), { type: parsedMeta.type }) }), _value);
        }
        else {
            for (var i in valuesArray) {
                switch (field.schema.type) {
                    case 'string':
                        if (typeof valuesArray[i] !== 'string')
                            throw new Error();
                        break;
                    case 'number':
                        if (isNaN(Number((_a = valuesArray[i]) !== null && _a !== void 0 ? _a : undefined)))
                            throw new Error();
                        else
                            valuesArray[i] = Number(valuesArray[i]);
                        break;
                    case 'boolean':
                        if (typeof Boolean(valuesArray[i]) !== 'boolean')
                            throw new Error();
                        else
                            valuesArray[i] = Boolean(valuesArray[i]);
                        break;
                    case 'date':
                        if (!Date.parse("".concat(valuesArray[i])))
                            throw new Error();
                        else
                            valuesArray[i] = new Date("".concat(valuesArray[i]));
                        break;
                    case 'object':
                        // if (typeof value !== 'object') throw new Error();
                        break;
                    case 'enum':
                        var properties = field.schema.properties;
                        if (!properties || !Array.isArray(properties))
                            throw new Error();
                        if (isNaN(Number((_b = valuesArray[i]) !== null && _b !== void 0 ? _b : undefined)))
                            throw new Error();
                        var valueNum = Number(valuesArray[i]);
                        if (valueNum < 0 || valueNum >= properties.length)
                            throw new Error();
                        for (var _i = 0, properties_1 = properties; _i < properties_1.length; _i++) {
                            var prop = properties_1[_i];
                            if (typeof prop.name !== 'string')
                                throw new Error();
                        }
                        valuesArray[i] = valueNum;
                        break;
                    case 'entity':
                        if (typeof valuesArray[i] !== 'string')
                            throw new Error();
                        break;
                    // if (!field.schema.properties) throw new Error();
                }
            }
        }
        return field.schema.multiple ? valuesArray : valuesArray[0];
    }
    catch (_c) {
        throw new Error("Invalid value for field ".concat(field.name));
    }
}
