import {
  AccountTreeRounded,
  Add,
  Assessment,
  AutoAwesome,
  Build,
  Check,
  HomeRounded,
  Logout,
  Payment,
  Person,
  PlaylistAdd,
  ReceiptLong,
  ShoppingBasket,
  StickyNote2,
  TodayOutlined,
  WarningAmber,
} from '@mui/icons-material';
import {
  alpha,
  Avatar,
  Box,
  BoxProps,
  CircularProgress,
  Drawer,
  DrawerProps,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Menu,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { useEntityActions } from 'api/entity';
import {
  useManageWorkspace,
  useWorkspace,
} from 'api/providers/workspaceProvider';
import { useCreateWorkspace } from 'components/createWorkspace';
import { AnimatePresence, motion } from 'framer-motion';
import useConfirm from 'hooks/useConfirm';
import { useIsTablet } from 'hooks/useIsMobile';
import { useDraggable } from 'providers/DnDProvider';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FinsightEntity } from 'shared';
import { useSWRConfig } from 'swr';
import { formatName } from 'utils/fields';
import { useUserPermissions } from 'utils/user';

export default function Sidebar(props: DrawerProps) {
  const isMobile = useIsTablet();
  const workspace = useWorkspace();
  const { key: location } = useLocation();

  const [workspaceMenuAnchorEl, setWorkspaceMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  const lastLocation = useRef(location);

  useEffect(() => {
    if (lastLocation.current !== location) {
      lastLocation.current = location;
      if (isMobile) {
        props.onClose?.({}, 'backdropClick');
      }
    }
  }, [isMobile, location, props]);

  return (
    <>
      <Drawer
        {...props}
        variant={isMobile ? 'temporary' : 'permanent'}
        PaperProps={{
          sx: {
            backgroundImage: 'unset',
          },
        }}
        sx={{
          ...props.sx,
          height: '100%',
          '&, & .MuiPaper-root': {
            width: 300,
          },
        }}
      >
        <Toolbar />
        <Stack flexGrow={1}>
          <List sx={{ bgcolor: 'transparent', flexGrow: 1 }}>
            <ListSubheader>For You</ListSubheader>
            {/* <Li icon={<Inbox />} text="Inbox" /> */}
            <Li icon={<HomeRounded />} text="Home" exact to="/" />
            <Li disabled icon={<Assessment />} text="Reports" />
            <Li disabled icon={<PlaylistAdd />} text="Coming Up" />
            <ListSubheader>Project Management</ListSubheader>
            <ProjectsLi />
            <Li icon={<TodayOutlined />} text="Tasks" disabled />
            <ListSubheader>Financial Management</ListSubheader>
            <Li disabled icon={<ReceiptLong />} text="Transactions" />
            <Li disabled icon={<ShoppingBasket />} text="Commodities" />
            <ListSubheader>Data Management</ListSubheader>
            <Li
              disabled
              icon={<AutoAwesome />}
              text="Data Maid"
              to="/datamaid"
            />
            <Li
              disabled
              icon={<StickyNote2 />}
              text="Scratch Pad"
              to="/scratchpad"
            />
            <ListSubheader>Admin</ListSubheader>
            <Li to="/field-editor" icon={<Build />} text="Field Editor" />
            <ListSubheader>Personal</ListSubheader>
            <Li toplevel to="/account" icon={<Person />} text="My Account" />
            <Li to="/404" icon={<Payment />} text="My Plan & Billing" />
          </List>
          <List>
            {workspace ? (
              <ListItemButton
                onClick={(e) => setWorkspaceMenuAnchorEl(e.currentTarget)}
              >
                <ListItemAvatar>
                  <Avatar>
                    {workspace.name
                      .split(' ')
                      .slice(0, 2)
                      .map((x) => x[0])
                      .join('')}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={workspace.name}
                  secondary={formatName(workspace.role)}
                />
                <ListItemSecondaryAction>
                  <IconButton size="small">
                    <Logout fontSize="small" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItemButton>
            ) : (
              'No workspace'
            )}
          </List>
        </Stack>
      </Drawer>
      <Menu
        anchorEl={workspaceMenuAnchorEl}
        open={Boolean(workspaceMenuAnchorEl)}
        onClose={() => setWorkspaceMenuAnchorEl(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <WorkspaceSwitcher onClose={() => setWorkspaceMenuAnchorEl(null)} />
      </Menu>
    </>
  );
}

function WorkspaceSwitcher({ onClose }: { onClose?: () => void }) {
  const { showCreateWorkspaceModal } = useCreateWorkspace();
  const userPermissions = useUserPermissions();
  const workspaceManager = useManageWorkspace();

  if (workspaceManager.workspaces.error) {
    return (
      <Stack py={1} px={2} direction="row" alignItems="center" gap={2}>
        <WarningAmber color="error" />
        <Typography>Error loading workspaces</Typography>
      </Stack>
    );
  }

  if (workspaceManager.workspaces.isLoading) {
    return (
      <Stack py={1} px={2} direction="row" alignItems="center" gap={2}>
        <CircularProgress size={32} />
        <Typography>Loading workspaces...</Typography>
      </Stack>
    );
  }

  const userWorkspaceCreatePermission = userPermissions.workspaces.create();

  return (
    <>
      {workspaceManager.workspaces.data?.map((workspace) => (
        <ListItemButton
          key={workspace.id}
          onClick={() => {
            workspaceManager.setCurrentWorkspace(workspace.id);
            onClose?.();
          }}
        >
          <ListItemAvatar>
            <Avatar>
              {workspace.name
                .split(' ')
                .slice(0, 2)
                .map((x) => x[0])
                .join('')}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={workspace.name}
            secondary={formatName(workspace.role)}
          />
          <ListItemSecondaryAction>
            <IconButton>
              {workspace.id === workspaceManager.workspaceId && <Check />}
            </IconButton>
          </ListItemSecondaryAction>
        </ListItemButton>
      ))}
      <ListItemButton
        disabled={!userWorkspaceCreatePermission.allowed}
        onClick={() => {
          if (userWorkspaceCreatePermission.allowed) {
            onClose?.();
            showCreateWorkspaceModal();
          }
        }}
        sx={{
          minWidth: 350,
        }}
      >
        <ListItemIcon>
          <Add />
        </ListItemIcon>
        <ListItemText
          primary="Create new workspace"
          secondary={
            userWorkspaceCreatePermission.allowed
              ? ''
              : userWorkspaceCreatePermission.message
          }
        />
      </ListItemButton>
    </>
  );
}

function ProjectsLi() {
  return (
    <Droppable
      alignTextLeft
      overlayText="Move to root"
      parentId={null}
      cachePurgeKeys={['/entity/project/root', '/entity/project/all']}
    >
      <Li icon={<AccountTreeRounded />} text="Projects" to="/projects" />
    </Droppable>
  );
}

type DroppableProps = {
  children?: React.ReactNode;
  boxProps?: BoxProps;
  alignTextLeft?: boolean;
  parentId?: string | null;
  cachePurgeKeys?: string[];
  overlayText?: string;
};

export function Droppable(props: DroppableProps) {
  const {
    children,
    boxProps,
    alignTextLeft,
    parentId,
    cachePurgeKeys,
    overlayText,
  } = props;

  const theme = useTheme();
  const { updateEntity } = useEntityActions();
  const draggableContext = useDraggable();
  const { cache, mutate } = useSWRConfig();
  const { confirm } = useConfirm();

  const [dropMode, setDropMode] = useState(false);

  useEffect(() => {
    if (!draggableContext.dragItem) {
      setDropMode(false);
    }
  }, [draggableContext.dragItem]);

  return (
    <Box
      {...boxProps}
      sx={{
        ...boxProps?.sx,
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '1fr',
        '& > *': {
          gridColumn: 1,
          gridRow: 1,
        },
      }}
      onMouseOver={() => {
        if (
          draggableContext.dragItem &&
          draggableContext.dragItem.type === 'entity'
        ) {
          setDropMode(true);
        }
      }}
      onMouseLeave={() => {
        setDropMode(false);
      }}
      onMouseUp={async () => {
        if (
          parentId !== undefined &&
          dropMode &&
          draggableContext.dragItem?.type === 'entity'
        ) {
          if (!(await confirm('Are you sure you want to move this entity?')))
            return;
          const droppedEntity = draggableContext.dragItem
            .data as FinsightEntity;
          try {
            const response = await updateEntity(droppedEntity.id, {
              parent: parentId,
            });
            if (!response.ok) throw new Error();

            const finalCachePurgeKeys = cachePurgeKeys ?? [];

            if (droppedEntity.parent) {
              finalCachePurgeKeys.push(
                `/entity/${droppedEntity.parent}/children`,
              );
            }

            if (finalCachePurgeKeys?.length) {
              Array.from(cache.keys())
                .filter((x) =>
                  finalCachePurgeKeys?.some((key) => x.includes(key)),
                )
                .forEach((key) => mutate(key));
            }
          } catch (e) {
            console.log("ERRORRR during drag and drop, couldn't move");
          }
        }
      }}
    >
      {children}
      <AnimatePresence>
        {dropMode && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            style={{
              zIndex: 1,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              padding: '8px 16px',
              justifyContent: alignTextLeft ? 'flex-start' : 'center',
              borderRadius: theme.shape.borderRadius,
              border: '1px dashed',
              borderColor: theme.palette.divider,
              background: alpha(theme.palette.background.paper, 0.1),
              backdropFilter: 'blur(5px) saturate(2)',
              overflow: 'hidden',
              cursor: 'pointer',
            }}
          >
            <motion.div
              initial={{
                top: -50,
                opacity: 0,
              }}
              animate={{
                top: 0,
                opacity: 1,
              }}
              style={{
                position: 'relative',
                pointerEvents: 'none',
              }}
            >
              <Typography>
                {overlayText ?? 'Drop here to move into this entity'}
              </Typography>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
}

function Li(props: {
  icon?: React.ReactNode;
  to?: string;
  text: string;
  disabled?: boolean;
  toplevel?: boolean;
  exact?: boolean;
}) {
  const toplevel = props.toplevel ?? false;
  const { pathname } = useLocation();
  const workspace = useWorkspace();

  const pathnameWithoutWorkspace = pathname.replace(
    `/workspace/${workspace?.id}`,
    '',
  );

  return (
    <ListItemButton
      href={
        props.to
          ? toplevel
            ? `${props.to}?workspace=${workspace?.id}`
            : workspace
              ? `/workspace/${workspace.id}${props.to}`
              : ''
          : ''
      }
      disabled={props.disabled}
      selected={
        props.to
          ? props.exact
            ? pathnameWithoutWorkspace === props.to
            : pathnameWithoutWorkspace.startsWith(props.to)
          : false
      }
    >
      {props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}
      <ListItemText primary={props.text} />
    </ListItemButton>
  );
}
