import { Alert, Button, Stack, TextField, Typography } from '@mui/material';
import { EndpointUrl } from 'api/endpoint';
import React, { useEffect, useState } from 'react';

export default function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [showPassword, setShowPassword] = useState(false);

  const login = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${EndpointUrl}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        if ('token' in data) {
          localStorage.setItem('token', `${data.token}`);
        }

        if ('expires' in data) {
          localStorage.setItem('expires', `${data.expires}`);
        }

        window.location.href = '/';
      } else {
        setError('Incorrect username or password');
      }
    } catch (e) {
      if (e instanceof Error && e.message === 'Failed to fetch') {
        if (navigator.onLine) {
          setError(
            'Looks like our systems are not working at the moment. Please try again later.',
          );
        } else {
          setError(
            'You are offline. Please check your internet connection and try again.',
          );
        }
      } else {
        setError('Uh oh! Something went wrong. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setError('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, password]);

  return (
    <Stack
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
      direction="row"
      bgcolor="background.paper"
    >
      <Stack flex="0 1 400px" gap={2}>
        {error && <Alert severity="error">{error}</Alert>}
        {/* <Stack
          borderRadius={2}
          p={2}
          gap={3}
          bgcolor="background.paper"
          alignItems="center"
          direction="row"
          sx={{
            backgroundImage:
              'linear-gradient(rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09))',
          }}
        >
          <Avatar
            sx={{
              width: 48,
              height: 48,
            }}
          >
            NA
          </Avatar>
          <Stack flexGrow={1}>
            <Typography variant="h6">Nasser Akhter</Typography>
            <Typography>nasrj3@gmail.com</Typography>
          </Stack>
          <Button>Continue</Button>
        </Stack> */}
        <Stack
          borderRadius={2}
          p={2}
          gap={1}
          bgcolor="background.paper"
          sx={{
            backgroundImage:
              'linear-gradient(rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09))',
          }}
        >
          <Stack alignItems="center" mt={2} mb={3}>
            <Typography
              lineHeight={0.8}
              variant="h4"
              textTransform="uppercase"
              fontWeight="500"
            >
              Finsight
            </Typography>
            <Typography
              ml="4px"
              variant="body2"
              textTransform="uppercase"
              letterSpacing="0.33em"
            >
              By Microart
            </Typography>
          </Stack>
          <form
            name="login"
            onSubmit={(e) => {
              e.preventDefault();
              login();
            }}
            style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
          >
            <TextField
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              type={showPassword ? 'text' : 'password'}
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              onClick={() => setShowPassword((prev) => !prev)}
              sx={{ p: 0, alignSelf: 'flex-end' }}
            >
              {showPassword ? 'Hide' : 'Show'} password
            </Button>
            <Button
              variant="contained"
              disabled={loading || !!error}
              type="submit"
            >
              Sign In
            </Button>
          </form>
          <Button disabled variant="outlined">
            Forgot Password
          </Button>
          <Button disabled>New to Finsight? Sign Up</Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
