import { Checkbox, Stack, TextField, Typography } from '@mui/material';
import { useField } from 'api/fieldEditor';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export default function FieldEditorPage() {
  const params = useParams();

  const fieldType = params.fieldType ?? null;
  const fieldName = params.fieldName ?? null;

  const fieldTypes = useField(fieldType);

  const fieldData = useMemo(
    () => fieldTypes.data?.find((x) => x.name === fieldName),
    [fieldName, fieldTypes.data],
  );

  return (
    <Stack gap={2} mt={2}>
      <Stack>
        <Typography fontWeight="bold">Field Editor</Typography>
        <Typography variant="h5">{fieldData?.name}</Typography>
      </Stack>
      <TextField
        label="Name"
        value={fieldData?.name}
        InputProps={{
          readOnly: true,
        }}
      />
      <TextField
        label="Type"
        value={fieldData?.type}
        InputProps={{
          readOnly: true,
        }}
      />
      <Stack direction="row" alignItems="center" gap={1}>
        <Checkbox checked={fieldData?.schema.sortable} />
        <Typography>Sortable</Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={1}>
        <Checkbox checked={fieldData?.schema.required} />
        <Typography>Required</Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={1}>
        <Checkbox checked={fieldData?.schema.groupable} />
        <Typography>Groupable</Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={1}>
        <Checkbox checked={fieldData?.schema.multiple} />
        <Typography>Multiple</Typography>
      </Stack>
      <TextField value={fieldData?.schema.default ?? 'Null'} />
      <TextField value={fieldData?.schema.order ?? 0} />
      {fieldData?.schema.constraints?.map((x) => (
        <Stack key={x.name}>
          <TextField value={x.property} />
          <TextField value={x.operator} />
          <TextField value={x.value} />
        </Stack>
      ))}
      {/* <TextField value={fieldData?.schema.meta} /> */}
      {(Array.isArray(fieldData?.schema.properties)
        ? fieldData?.schema.properties
        : Object.values(fieldData?.schema.properties ?? {})
      ).map((x) => (
        <Stack key={x.name}>
          <TextField value={JSON.stringify(x)} />
        </Stack>
      ))}
    </Stack>
  );
}
