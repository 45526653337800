import { Add, Delete, MoreHoriz, OpenInNew } from '@mui/icons-material';
import {
  Breadcrumbs,
  Card,
  CardActionArea,
  Chip,
  Divider,
  IconButton,
  Link,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  PopoverVirtualElement,
  Popper,
  Stack,
  Typography,
} from '@mui/material';
import { useEntityActions } from 'api/entity';
import { useWorkspaceId } from 'api/providers/workspaceProvider';
import { useCreateEntityModal } from 'components/createEntity';
import { useNotifications } from 'components/notify';
import { FinsightEntityFieldValue } from 'components/renderField';
import { Droppable } from 'components/sidebar';
import { animate, motion, useMotionValue } from 'framer-motion';
import { useDraggable } from 'providers/DnDProvider';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FinsightEntity, FinsightEntityParent } from 'shared';
import { useSWRConfig } from 'swr';

type EntityComponentProps = {
  entity?: FinsightEntity | FinsightEntityParent;
  viewType?: 'list' | 'card' | 'text';
  hideType?: boolean;
  hideParent?: boolean;
  parentControlledCardSize?: boolean;
};

function EntityComponent(
  {
    entity,
    viewType,
    hideType,
    hideParent,
    parentControlledCardSize,
  }: EntityComponentProps,
  ref: React.Ref<HTMLDivElement>,
) {
  const navigate = useNavigate();
  const { cache, mutate } = useSWRConfig();
  const { showNotification } = useNotifications();
  const { showCreateEntityModal } = useCreateEntityModal();

  const [searchParams] = useSearchParams();

  const [anchorEl, setAnchorEl] = useState<null | PopoverVirtualElement>(null);

  const draggableContext = useDraggable();
  const { deleteEntity } = useEntityActions();
  const workspaceId = useWorkspaceId();

  const [dragging, setDragging] = useState(false);
  const onMouseMove = useRef<((e: MouseEvent) => void) | null>(null);
  const onMouseUp = useRef<((e: MouseEvent) => void) | null>(null);
  const popperRef = useRef<HTMLDivElement | null>(null);
  const elemRef = useRef<HTMLDivElement | null>(null);
  const origMouseOffsetX = useMotionValue(0);
  const origMouseOffsetY = useMotionValue(0);
  const mouseCoords = useRef({ x: 0, y: 0 });

  useEffect(() => {
    return () => {
      if (onMouseMove.current) {
        document.removeEventListener('mousemove', onMouseMove.current);
      }
      if (onMouseUp.current) {
        document.removeEventListener('mouseup', onMouseUp.current);
      }
    };
  }, []);

  let component: React.ReactNode;

  if (!entity) return null;

  if (typeof ref === 'function') {
    ref(elemRef.current);
  }

  const handleDrag = (e: React.DragEvent) => {
    e.preventDefault();
    setDragging(true);

    draggableContext.setDragItem({
      type: 'entity',
      data: entity,
    });

    const elemCoords = elemRef.current!.getBoundingClientRect();
    origMouseOffsetX.set(e.clientX - elemCoords.x);
    origMouseOffsetY.set(e.clientY - elemCoords.y);

    const animateHandlerX = origMouseOffsetX.on('change', (val) => {
      popperRef.current!.style.left = `${mouseCoords.current.x - val}px`;
    });

    const animateHandlerY = origMouseOffsetY.on('change', (val) => {
      popperRef.current!.style.top = `${mouseCoords.current.y - val}px`;
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onMouseMove.current = (e: MouseEvent) => {
      mouseCoords.current = { x: e.clientX, y: e.clientY };
      if (popperRef.current) {
        popperRef.current.style.left = `${e.clientX - origMouseOffsetX.get()}px`;
        popperRef.current.style.top = `${e.clientY - origMouseOffsetY.get()}px`;
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onMouseUp.current = (e: MouseEvent) => {
      setDragging(false);
      draggableContext.setDragItem(null);
      animateHandlerX();
      animateHandlerY();
      window.removeEventListener('mousemove', onMouseMove.current!);
      window.removeEventListener('mouseup', onMouseUp.current!);
    };

    window.addEventListener('mousemove', onMouseMove.current);
    window.addEventListener('mouseup', onMouseUp.current);

    animate(origMouseOffsetX, 0, {
      // duration: 0.5,
      type: 'spring',
      stiffness: 100,
      damping: 15,
      velocity: 0,
    });
    animate(origMouseOffsetY, 0, {
      // duration: 0.5,
      type: 'spring',
      stiffness: 100,
      damping: 15,
      velocity: 0,
    });
  };

  if (viewType === 'text') {
    component = (
      <Link
        key={entity.id}
        href={`/workspace/${workspaceId}/entity/${entity.id}?${searchParams}`}
      >
        {entity.name}
      </Link>
    );
  } else if (viewType === 'card' && 'description' in entity) {
    component = (
      <Card
        ref={elemRef}
        variant="outlined"
        key={entity.id}
        sx={{
          ...(!parentControlledCardSize
            ? { width: 275, height: 250, flexShrink: 0 }
            : {}),
        }}
        onContextMenu={(e) => {
          e.preventDefault();
          setAnchorEl({
            nodeType: 1,
            getBoundingClientRect: () => {
              return {
                x: e.clientX,
                y: e.clientY,
                top: e.clientY,
                left: e.clientX,
                right: e.clientX,
                bottom: e.clientY,
                height: 0,
                width: 0,
                toJSON: () => '',
              };
            },
          });
        }}
        onDragStart={handleDrag}
      >
        <Stack height="100%">
          <CardActionArea
            disableRipple
            href={`/workspace/${workspaceId}/entity/${entity.id}?${searchParams}`}
            sx={{ flexGrow: 1, minHeight: 0, overflow: 'hidden' }}
          >
            <Stack p={2}>
              <Typography variant="h5">
                {entity.name.length > 40
                  ? `${entity.name.slice(0, 40)}...`
                  : entity.name}
              </Typography>
              {Object.values(entity.extraFieldsMeta).map((field, index) => (
                <FinsightEntityFieldValue
                  key={index}
                  field={field}
                  value={entity.extraFields[field.name]}
                  viewType="card"
                />
              ))}
              {/* <Stack direction="row" alignItems="center" gap={2}>
                {PriorityIcons[entity.priority]?.icon}
                <Typography>{PriorityIcons[entity.priority]?.label}</Typography>
              </Stack>
              {entity.deadline && (
                <Chip
                  label={'Due ' + moment(entity.deadline).fromNow()}
                  color="error"
                  variant="outlined"
                  size="small"
                  sx={{
                    alignSelf: 'flex-start',
                  }}
                />
              )}
              <Typography>{entity.status}</Typography>
              <Typography>{entity.cost_estimate}</Typography>
              <Typography>{entity.cost_maximum}</Typography> */}
            </Stack>
          </CardActionArea>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            px={2}
            py={0.5}
            flexShrink={0}
            borderTop="1px solid"
            borderColor="divider"
          >
            {!hideParent && (
              <Breadcrumbs>
                {entity.parents?.toReversed().map((parent) => (
                  <Link
                    color="text.secondary"
                    key={parent.id}
                    href={`/workspace/${workspaceId}/entity/${parent.id}?${searchParams}`}
                  >
                    {parent.name.length > 10
                      ? `${parent.name.slice(0, 10)}...`
                      : parent.name}
                  </Link>
                ))}
                {entity.parents?.length && (
                  <Typography>
                    {entity.name.length > 10
                      ? `${entity.name.slice(0, 10)}...`
                      : entity.name}
                  </Typography>
                )}
              </Breadcrumbs>
            )}
            <IconButton
              size="small"
              onClick={(e) => {
                const rect = e.currentTarget.getBoundingClientRect();
                setAnchorEl({
                  nodeType: 1,
                  getBoundingClientRect: () => rect,
                });
              }}
            >
              <MoreHoriz />
            </IconButton>
          </Stack>
        </Stack>
      </Card>
    );
  } else if ('description' in entity) {
    component = (
      <Stack direction="row" ref={elemRef}>
        <ListItemButton
          onContextMenu={(e) => {
            e.preventDefault();
            setAnchorEl({
              nodeType: 1,
              getBoundingClientRect: () => ({
                x: e.clientX,
                y: e.clientY,
                top: e.clientY,
                left: e.clientX,
                right: e.clientX,
                bottom: e.clientY,
                height: 0,
                width: 0,
                toJSON: () => '',
              }),
            });
          }}
          draggable
          disableRipple
          href={`/workspace/${workspaceId}/entity/${entity.id}?${searchParams}`}
          onDragStart={handleDrag}
        >
          {!hideType && <Chip size="small" label={entity.type} />}
          {/* <Stack gap={1} direction="row" alignItems="center" px={2}>
            {PriorityIcons[entity.priority]?.icon}
            <Typography>{PriorityIcons[entity.priority]?.label}</Typography>
          </Stack> */}
          <Stack gap={1} direction="row" alignItems="center" px={2}>
            {Object.values(entity.extraFieldsMeta).map((field, index) => (
              <FinsightEntityFieldValue
                key={index}
                field={field}
                value={entity.extraFields[field.name]}
                viewType="list"
              />
            ))}
          </Stack>
          <Stack direction="row">
            {entity.parents?.length && !hideParent && (
              <Typography mr={2} color="text.secondary">
                {entity.parents
                  ?.toReversed()
                  .map((parent) =>
                    parent.name.length > 10
                      ? `${parent.name.slice(0, 10)}...`
                      : parent.name,
                  )
                  .join(' / ')}
              </Typography>
            )}
            <Typography mr={2}>&mdash;</Typography>
            <Typography>{entity.name}</Typography>
          </Stack>
        </ListItemButton>
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            const rect = e.currentTarget.getBoundingClientRect();
            setAnchorEl({
              nodeType: 1,
              getBoundingClientRect: () => rect,
            });
          }}
        >
          <MoreHoriz />
        </IconButton>
      </Stack>
    );
  } else {
    return null;
  }

  const elemSize = elemRef.current?.getBoundingClientRect();

  return (
    <>
      <Droppable
        alignTextLeft={viewType !== 'card'}
        boxProps={{
          sx: {
            pointerEvents: dragging ? 'none' : 'auto',
            opacity: dragging ? 0.4 : 1,
          },
        }}
        cachePurgeKeys={[
          ...('description' in entity
            ? [
                `/workspace/${workspaceId}/entities/${entity.type}/root`,
                `/workspace/${workspaceId}/entities/${entity.type}/all`,
              ]
            : []),
          `/workspace/${workspaceId}/entity/${entity.id}/children`,
        ]}
        parentId={entity.id}
      >
        {component}
      </Droppable>
      {'description' in entity && (
        <>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {/* <MenuItem dense onClick={() => navigate(`/entity/${entity.id}`)}>
          <ListItemIcon>
            <OpenInNew />
          </ListItemIcon>
          <ListItemText primary="View Entity" />
        </MenuItem> */}
            {entity.parent && (
              <MenuItem
                dense
                onClick={() =>
                  navigate(
                    `/workspace/${workspaceId}/entity/${entity.parent}?${searchParams}`,
                  )
                }
              >
                <ListItemIcon>
                  <OpenInNew />
                </ListItemIcon>
                <ListItemText primary="View Parent Entity" />
              </MenuItem>
            )}
            {entity.parent && <Divider />}
            <MenuItem
              dense
              onClick={() => showCreateEntityModal('project', entity)}
            >
              <ListItemIcon>
                <Add />
              </ListItemIcon>
              <ListItemText primary="Create Sub-Project" />
            </MenuItem>
            <Divider />
            <MenuItem
              dense
              onClick={async () => {
                setAnchorEl(null);
                try {
                  const response = await deleteEntity(entity.id);
                  if (!response.ok) {
                    const data = await response.json();
                    throw new Error(data.message);
                  }
                  if (entity.parent) {
                    Array.from(cache.keys())
                      .filter((x) =>
                        x.includes(
                          `/workspace/${workspaceId}/entity/${entity.parent}`,
                        ),
                      )
                      .forEach((key) => mutate(key));
                  } else {
                    Array.from(cache.keys())
                      .filter((x) =>
                        x.includes(
                          `/workspace/${workspaceId}/entities/${entity.type}/root`,
                        ),
                      )
                      .forEach((key) => mutate(key));
                  }
                } catch (e) {
                  const message =
                    e instanceof Error ? e.message : 'An error occurred';
                  showNotification(message, {
                    variant: 'error',
                  });
                  console.log(message);
                }
              }}
            >
              <ListItemIcon>
                <Delete color="error" />
              </ListItemIcon>
              <ListItemText primary="Delete" sx={{ color: 'error.main' }} />
            </MenuItem>
          </Menu>
          <Popper
            ref={popperRef}
            open={dragging}
            sx={{
              zIndex: 2000,
            }}
          >
            <motion.div
              initial={{
                width: elemSize?.width,
                height: elemSize?.height,
                opacity: 0.5,
              }}
              animate={{
                width: 0,
                height: 0,
                opacity: 1,
              }}
              transition={{
                // duration: 0.3,
                // ease: 'easeOut',
                type: 'spring',
                stiffness: 120,
                damping: 15,
                velocity: 0,
              }}
              style={{
                pointerEvents: 'none',
              }}
            >
              <Stack
                sx={{
                  width: '100%',
                  height: '100%',
                  minWidth: 'fit-content',
                  bgcolor: 'background.paper',
                  backgroundImage:
                    'linear-gradient(rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09))',
                  borderRadius: 1.5,
                  boxShadow: 15,
                  borderColor: 'divider',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  direction: 'row',
                  justifyContent: 'center',
                  padding: 2.5,
                }}
              >
                <Typography whiteSpace="nowrap">{entity.name}</Typography>
              </Stack>
            </motion.div>
          </Popper>
        </>
      )}
    </>
  );
}

export default forwardRef(EntityComponent);
