import {
  Card,
  CardActionArea,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';

type CardButtonProps = {
  icon: React.ReactNode;
  title: string;
  description: string;
  onClick?: () => void;
};

export default function CardButton(props: CardButtonProps) {
  return (
    <Card variant="outlined">
      <CardActionArea onClick={props.onClick}>
        <CardContent>
          <Stack
            gap={1}
            alignItems="center"
            justifyContent="center"
            sx={{
              minWidth: 100,
              minHeight: 100,
              p: 1,

              '.MuiSvgIcon-root': {
                fontSize: '3rem',
              },
            }}
          >
            {props.icon}
            <Typography variant="h6" textTransform="uppercase">
              {props.title}
            </Typography>
            <Typography>{props.description}</Typography>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
