import { Button, Stack, Typography } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { useField, useFieldTypes } from 'api/fieldEditor';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { formatName } from 'utils/fields';

export default function FieldEditorFieldsPage() {
  const params = useParams();
  const fieldTypes = useFieldTypes();

  const fieldTypeParam = params.fieldType ?? '';

  const { data: selectedFieldType } = useMemo(() => {
    return {
      ...fieldTypes,
      data:
        (fieldTypes.data?.findIndex((x) => x.type === fieldTypeParam) ?? -1) >=
        0
          ? fieldTypeParam
          : null,
    };
  }, [fieldTypeParam, fieldTypes]);

  const fieldData = useField(selectedFieldType);

  return (
    <Stack mt={3}>
      <Typography fontWeight="bold">Fields</Typography>
      <Typography variant="h5">
        {formatName(selectedFieldType ?? '')}
      </Typography>
      <DataGridPremium
        rows={fieldData.data ?? []}
        getRowId={(row) => row.name}
        columns={[
          {
            field: 'name',
            headerName: 'Name',
            flex: 1,
          },
          {
            field: 'type',
            headerName: 'Type',
            valueGetter(_, row) {
              return row.schema.type;
            },
            flex: 1,
          },
          {
            field: 'sortable',
            headerName: 'Sortable',
            valueGetter(_, row) {
              return row.schema.sortable ?? false;
            },
            flex: 1,
          },
          {
            field: 'required',
            headerName: 'Required',
            valueGetter(_, row) {
              return row.schema.required ?? false;
            },
            flex: 1,
          },

          {
            field: 'order',
            headerName: 'Order',
            valueGetter(_, row) {
              return row.schema.order ?? 0;
            },
            flex: 1,
          },

          {
            field: 'multiple',
            headerName: 'Multiple',
            valueGetter(_, row) {
              return row.schema.multiple ?? false;
            },
            flex: 1,
          },
          {
            field: 'groupable',
            headerName: 'Groupable',
            valueGetter(_, row) {
              return row.schema.groupable ?? false;
            },
            flex: 1,
          },
          {
            field: 'default',
            headerName: 'Default',
            valueGetter(_, row) {
              return row.schema.default ?? 'Null';
            },
            flex: 1,
          },
          {
            field: 'edit',
            headerName: 'Edit',
            renderCell(params) {
              return <Button href={`${params.row.name}`}>Edit</Button>;
            },
            width: 100,
          },
        ]}
      />
    </Stack>
  );
}
